import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule, SwRegistrationOptions } from '@angular/service-worker';
import { QuicklinkModule } from 'ngx-quicklink';
import { environment } from '../environments/environment';
import { PageNotFoundComponent } from './_security/page-not-found/page-not-found.component';
import { BreadcrumbModule } from 'angular-crumbs';
import { LayoutModule } from '@angular/cdk/layout';
import { ValidationService } from './_security/validation.service';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { HttpClientModule } from '@angular/common/http';
import { DataService } from './data.service';

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    QuicklinkModule,
    BreadcrumbModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js'),
    LayoutModule,
    RxReactiveFormsModule,
    HttpClientModule

  ],
  providers: [
    {
      provide: SwRegistrationOptions,
      useFactory: () => ({ enabled: environment.production }),
    },
    BreadcrumbModule,
    ValidationService,
    DataService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
