import { Component, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { Title } from '@angular/platform-browser';
import { BreadcrumbService, Breadcrumb } from 'angular-crumbs'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {

  update: boolean = false;

  title = 'fin-frontend';

  ribbon: boolean = true;

  constructor(private _updates: SwUpdate, private titleService: Title, private breadcrumbService: BreadcrumbService) {

    _updates.available.subscribe(event => {
      this.update = true;
      _updates.activateUpdate().then(() => document.location.reload)
    })
  }

  ngOnInit(): void {
    this.breadcrumbService.breadcrumbChanged.subscribe(crumbs => {
      this.titleService.setTitle(this.createTitle(crumbs));
    })
  }

  private createTitle(routesCollection: Breadcrumb[]) {
    const title = 'Fin Bank';
    const titles = routesCollection.filter((route) => route.displayName);

    if (!titles.length) { return title; }

    const routeTitle = this.titlesToString(titles);
    return `${routeTitle} ${title}`;
  }

  private titlesToString(titles) {
    return titles.reduce((prev, curr) => {
      return `${curr.displayName} - ${prev}`;
    }, '');
  }


  /* Stupid ribbon close */
  closeRibbon() {
    this.ribbon = !this.ribbon;
  }
}