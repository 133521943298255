import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, ValidatorFn } from '@angular/forms'
@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  constructor() { }

  static isDecimal(): ValidatorFn {
    return (control: FormControl): { [key: string]: boolean } | null => {
      let val: string = control.value;

      if (control.pristine) return null

      const regex = new RegExp("^([1-9]+\\d*([.]\\d+)?)$|^(0[.]\\d*[1-9]+)$|^0$")
      const status = regex.test(val)

      return { 'isDecimal': status };
    }
  }
}
