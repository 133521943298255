import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  // baseUrl = "http://localhost:8080/fin/fin-datasending/src/app/api/";

  // baseUrl = "http://dhanavardhika.snmfi.in/api/";
  baseUrl = "https://dhanavardhikanidhiltd.sndpyogamvypinunion.com/api/";

  private generalLedgerSource = new BehaviorSubject<any>({ isValid: false, data: null });
  generalLedger$ = this.generalLedgerSource.asObservable();

  setGeneralLedger(formValid, formData) {
    this.generalLedgerSource.next({ isValid: formValid, data: formData });
  }

  constructor(private _http: HttpClient) { }

  getBranches() {
    // return this._http.get(`${environment._utilAPI}/branches`);
  }

  getHeadofAccounts() {
    // return this._http.get(`${environment._utilAPI}/hoas`);
  }

  loginUser(username, password) {
    let httpParams = new HttpParams()
      .append("email", username)
      .append("password", password);
    return this._http.post(this.baseUrl + 'login.php', httpParams);
  }

  getBankDetails(brid) {
    let httpParams = new HttpParams()
      .append("brid", brid);
    return this._http.post(this.baseUrl + 'fetchBankDetails.php', httpParams);
  }

  fetchBranchWithUserid(userid) {
    let httpParams = new HttpParams()
      .append("userid", userid);
    return this._http.post(this.baseUrl + 'fetchBranch.php', httpParams);
  }

  fetchGeneralData(params) {
    let httpParams = new HttpParams()
      .append("params", params);
    return this._http.post(this.baseUrl + 'fetchGeneralData.php', httpParams);
  }

  fetchCasteData(religionID) {
    let httpParams = new HttpParams()
      .append("religionID", religionID);
    return this._http.post(this.baseUrl + 'fetchCasteData.php', httpParams);
  }

  fetchAddressData() {
    return this._http.get(this.baseUrl + 'fetchAddressData.php');
  }

  fetchLocalbodyData(localbodyType) {
    let httpParams = new HttpParams()
      .append("localbodyType", localbodyType);
    return this._http.post(this.baseUrl + 'fetchLocalbodyData.php', httpParams);
  }

  fetchIdtypeData() {
    return this._http.get(this.baseUrl + 'fetchIdtypeData.php');
  }

  addCustomer(custData: any) {
    console.log(custData);
    let httpParams = new HttpParams()
      .append("custData", custData);
    return this._http.post(this.baseUrl + 'addCustomer.php', httpParams);
  }

  // shares new

  getMemberTypes(){
    return this._http.get(this.baseUrl + 'fetchMemberTypes.php');
  }

  getResolutions(){
    return this._http.get(this.baseUrl + 'getResolutions.php');
  }

  getMemberDetails(){
    return this._http.get(this.baseUrl + 'getMemberDetails.php');
  }

  getCertificateNo(mem_type){
    let httpParams = new HttpParams()
    .append("typeid", mem_type);
    return this._http.post(this.baseUrl + 'fetchCertificateNo.php', httpParams);
  }

  getAccountHeads() {
    return this._http.get(this.baseUrl + 'fetchAccountHeads.php');
  }

  searchCustomer(searchParams: any){
    let httpParams = new HttpParams()
    .append("searchParams", searchParams);
  return this._http.post(this.baseUrl + 'searchCustomer.php', httpParams);
  }

  searchAccounts(moduleName: string, moduleId: string, accountNumber: string) {
    let httpParams = new HttpParams()
    .append("moduleName", moduleName)
    .append("moduleId", moduleId)
    .append("accountNumber", accountNumber);
  return this._http.post(this.baseUrl + 'searchAccounts.php', httpParams);
  }

  addMemberAppData(transactionForm,selectedCustomerData,transactionData,transferData,nomineeData,memberFormMaster,memberFormTrans){
    let httpParams = new HttpParams()
    .append("transactionForm", transactionForm)
    .append("selectedCustomerData", selectedCustomerData)
    .append("transactionData", transactionData)
    .append("transferData", transferData)
    .append("nomineeData", nomineeData)
    .append("memberFormMaster", memberFormMaster)
    .append("memberFormTrans", memberFormTrans);

    return this._http.post(this.baseUrl + 'addMember.php', httpParams)
  }

  fetchMemberData(params){
    let httpParams = new HttpParams()
    .append("params", params);
    return this._http.post(this.baseUrl + 'fetchMemberData.php', httpParams)
  }

  fetchUserData(params){
    let httpParams = new HttpParams()
    .append("params", params);
    return this._http.post(this.baseUrl + 'fetchUserData.php', httpParams)
  }

  fetchMasterData(params){
    let httpParams = new HttpParams()
    .append("params", params);
    return this._http.post(this.baseUrl + 'fetchMasterData.php', httpParams)
  }

  addMasterData(type, name, userid, crdate){
    let httpParams = new HttpParams()
    .append("type", type)
    .append("name", name)
    .append("userid", userid)
    .append("crdate", crdate);
    return this._http.post(this.baseUrl + 'addMasterData.php', httpParams)
  }

  createUser(username,usercode,password,crdate,brid){
    let httpParams = new HttpParams()
    .append("username", username)
    .append("usercode", usercode)
    .append("password", password)
    .append("crdate", crdate)
    .append("brid", brid);
    return this._http.post(this.baseUrl + 'createUser.php', httpParams)
  }

  getPrevilege(userid){
    let httpParams = new HttpParams()
    .append("userid", userid);
    return this._http.post(this.baseUrl + 'fetchPrevilege.php', httpParams)
  }
  getPrevilegeId(previlege){
    let httpParams = new HttpParams()
    .append("previlege", previlege);
    return this._http.post(this.baseUrl + 'fetchPrevilegeId.php', httpParams)
  }
}