import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { QuicklinkStrategy } from 'ngx-quicklink';

const routes: Routes = [
  {
    path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
    data: {
      breadcrumb: 'Authentication'
    }
  },
  {
    path: 'reports', loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
    data: {
      breadcrumb: 'Reports'
    }
  },
  {
    path: 'verification', loadChildren: () => import('./verification/verification.module').then(m => m.VerificationModule),
    data: {
      breadcrumb: 'verification'
    }
  },
  {
    path: 'views', loadChildren: () => import('./views/views.module').then(m => m.ViewsModule),
    data: {
      breadcrumb: 'Views'
    }
  },
  {
    path: 'head-office', loadChildren: () => import('./head-office/head-office.module').then(m => m.HeadOfficeModule),
    data: {
      breadcrumb: 'Head Office'
    }
  },
  {
    path: 'staff', loadChildren: () => import('./layouts/layouts.module').then(m => m.LayoutsModule),
    data: {
      breadcrumb: 'Staff'
    }
  },
  {
    path: 'manager', loadChildren: () => import('./manager/manager.module').then(m => m.ManagerModule),
    data: {
      breadcrumb: 'Manager'
    }
  },
  {
    path: 'interest', loadChildren: () => import('./interest/interest.module').then(m => m.InterestModule),
    data: {
      breadcrumb: 'Interest'
    }
  },
  {
    path: 'settings', loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
    data: {
      breadcrumb: 'Settings'
    }
  },
  {
    path: 'verification', loadChildren: () => import('./verification/verification.module').then(m => m.VerificationModule),
    data: {
      breadcrumb: 'Verification'
    }
  },


  { path: '**', redirectTo: '/auth/login', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: QuicklinkStrategy })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
